<template>
  <section class="main-section">
    <div class="news-section" v-if="news.total > 0">
      <div class="news-card" v-for="(n, i) in news.data" :key="i">
        <a :href="'/new/' + n.id">
          <div class="news-content">
            <div class="news-title">{{ n.title }}</div>
            <div class="news-date">{{n.create_time.substring(0, 10)}}</div>
          </div>
          <div class="news-img">
            <img :src="n.attach.path"/>
          </div>
        </a>
      </div>
      <template v-if="totalPage > 1">
        <div class="text-center">
          <v-pagination v-model="page" :length="totalPage"></v-pagination>
        </div>
      </template>
    </div>
  </section>
</template>
<script>
export default {
  data: () => ({
    news: [],
    totalPage: 1,
    type: 2,
    page: 1
  }),
  created () {
    this.$store.commit('updateActiveNav', null)
    this.type = 2
    this.page = 1
    this.getNews(this.type, this.page)
  },
  mounted () {
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
      },
      deep: true
    },
    params: {
      handler: function (val, oldVal) {
        let type = val.type
        let page = val.page
        this.getNews(type, page)
      },
      deep: true
    }
  },
  computed: {
    info () {
      return this.$store.getters.companyInfo
    },
    params () {
      const { type, page } = this
      return { type, page }
    }
  },
  methods: {
    getNews (type, page) {
      this.totalPage = 1
      this.https.get('news', { type: type, page: page, pageSize: 10 }).then(response => {
        if (response.code === 0) {
          this.news = response.data
          this.totalPage = this.news.last_page
          this.page = page
          window.scrollTo(0, 0)
        }
      })
    }
  }
}
</script>
<style lang="css">
  @import '~@/assets/css/about.css';
</style>
